import dayjs from 'dayjs'
import dayjsPluginUTC from 'dayjs/plugin/utc'
dayjs.extend(dayjsPluginUTC)

/**
 * 格式化时间
 *
 * @param {String} str
 * @returns 格式化后的时间
 */

/* eslint-disable */
export const formatTime = (date: any, transform?: any) => {
  if(!date){
    return '';
  } else if (typeof date == 'number') {
    date = String(date).length < 13
      ? new Date(date * 1000)
      : new Date(date)
  } else if (isNaN(Date.parse(date))) {
    date = new Date( Date.parse(date.replace(/-/g, "/")));
  } else if (date instanceof Date) {
    // 取本地时间时, 格式化为utc(零时区时间) 然后增加480分钟调整为中国大陆时区
    date = dayjs.utc(date).utcOffset(480)
  } else {
    date = new Date( Date.parse(date) );
  }
  
  // 统一转换为dayjs对象
  date = date instanceof dayjs
    ? date
    : dayjs(date)

  // transform 转换，兼容旧的yyyy dd
  // dayjs 标准格式化为 YYYY-MM-DD HH:mm:ss
  transform = transform
    ? transform
      .replace('yyyy', 'YYYY')
      .replace('dd', 'DD')
      .replace('SS', 'ss')
    : 'YYYY-MM-DD HH:mm:ss'

  return date.format(transform)
};

export const timestampToDatetime = (ts: any) => {
  // ts: utc 时间戳 精度秒
  return new Date(ts * 1000);
};
