import * as utils from '@/modules/exam/filters/utils'
import uuidv4 from 'uuid/v4'
import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import commonMixin from './commonMixin'

@Component
export default class ClassWrap extends Mixins(commonMixin) {
  // @Prop() id!: any
  // mixins: [ commonMixin ],
  // methods: {
  // 格式化题目的数据
  handleSubject(data: any, cardList: any) {
    // console.log(data, cardList, '有没有进来');
    
    let subject: any = {}
    if (data.type === 'combination') { // 阅读理解，需要把每一小题拆开放在题目列表中
      
      if (data.content.attachment.audio) {
        data.content.attachment.audio = this.handleAudio(data.content.attachment.audio)
      }
      let obj = {}
      let list = this.allCardList.concat()
      let arr: any = []
      const index = list.findIndex((v: any) => v.id === data.id)
      data.sub_questions.forEach((sub: any, i: any) => {
        Object.assign(sub, {
          id: data.id,
          childId: sub.id,
          type: data.type,
          childType: sub.type,
          title: data.content.topic,
          scoreTotal: data.score,
          parentAttachment: data.content.attachment
        })
        obj = this.dealData(sub, cardList, i)
        if (i === 0) subject = obj // 把第一道小题设置为当前题目
        arr.push({
          id: sub.id,
          childId: sub.childId
        })
        this.$store.dispatch('setSubjectItem', obj) // 把每道小题插入到题目列表中
      })
      list.splice(index, 1, ...arr) // 把所有小题替换掉大题
      this.$store.commit('SET_CARD_LIST', list) // 更新小题的答题卡列表
    } else {
      console.log('题目');
      
      data.childId = data.id
      subject = this.dealData(data, cardList)
      this.$store.dispatch('setSubjectItem', subject)
    }
    // subjectInfo
    this.$store.commit('SET_CUR_SUBJECY', subject)
    // this.$store.commit('SET_CUR_SUBJECY', subject)
  }
  dealData(data: any, cardList: any, i?: any) {
    // console.log(data, cardList, 'cardList');
    
    if (data.content.options) {
      data.options = this.handleOptions(data.content.options)
    }
    if (data.content.attachment && data.content.attachment.audio) {
      data.content.attachment.audio = this.handleAudio(data.content.attachment.audio)
    }
    if (data.content.analysis_attachment && data.content.analysis_attachment.audio) {
      data.content.analysis_attachment.audio = this.handleAudio(data.content.analysis_attachment.audio)
    }
    if (data.remark && data.remark.audio) {
      data.remark.audio = this.handleAudio(data.remark.audio)
    }
    const index = cardList.findIndex((i: any) => i.childId === data.id)
    const subject = {
      tid: uuidv4(),
      seqText: data.type === 'combination' ? `${cardList[index].seq}-${i + 1}` : cardList[index].seq,
      id: data.id,
      childId: data.childId, // 小题的id
      answer: data.answer || [], // 用户答案
      isAnswer: !!data.answer, // 是否已经大题
      type: data.type, // 题目类型
      childType: data.childType, // 子题目类型
      detail: data
    }
    console.log(subject, 'subject');
    
    return subject
  }
  // 处理选项,添加英文标号
  handleOptions(options: any) {
    options.map((optItem: any, optIndex: any) => {
      optItem.selectTip = utils.getEnglishChar(optIndex)
      const optAudio = optItem.attachment.audio
      if (optAudio) {
        optItem.attachment.audio = this.handleAudio(optAudio)
      }
    })
    return options
  }
  // 处理音频文件，为音频文件加上唯一的tid
  handleAudio(audios: any) {
    audios.map((item: any) => {
      item.tid = uuidv4()
    })
    return audios
  }
  // 重置题目的数据
  resetSubject(subjectInfo: any) {
    subjectInfo.detail.content.options && subjectInfo.detail.content.options.forEach((item: any) => {
      item.status = ''
    })
    this.$store.dispatch('setSubjectItem', subjectInfo)
  }
  // }
}