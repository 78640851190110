






























































import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import {
  getExamDetail
} from '@/modules/course/api'
import { counterTimeBySec } from '@/modules/course/filters/utils'
import { eventBus } from '@/modules/exam/filters/event-bus'
import answerCard from './answer-card.vue'

@Component({
  components: {
    answerCard
    // dialogWrap
  }
})
export default class ClassWrap extends Vue {
  @Prop() examType!: any
  @Prop() renderType!: any
  @Prop() answerCardDetail!: any
  @Prop() examDetail!: any
  @Prop() id!: any
  @Prop({ default: false }) isProgress!: boolean
  @Prop({ default: false }) isCountdown!:boolean
  detail: any = {}
  timeText: any = {}
  centerDialogVisible: boolean = false
  timer:any =  null
  countdownText:any =  ''
  remainTime:any =  0 // 考试剩余时间

  get isSectionFreeStudy(){
    // 部分试学
    return !this.examDetail.has_subscribed && this.examDetail.trial_question_count > 0
  }

  get cardList() {
    const cards = this.cardDetail.answer_card
    cards.map((item: any, index: any) => {
      item.childId = item.id
      if (index === 0) {
        item.seq = item.type === 'rich_text' ? 0 : 1
      } else {
        item.seq = item.type === 'rich_text' ? cards[index - 1].seq : cards[index - 1].seq + 1
      }
    })
    return this.cardDetail.answer_card
  }

  get cardDetail() {
    return this.answerCardDetail
  }

  get unAnswerCount() {
    return this.cardDetail.skip_count
  }

  get curSubjectInfo() {
    return this.$store.state.exam.curSubjectInfo
  }

  get curSeq() {
    let obj = {}
    this.cardList.forEach((item: any) => {
      if (item.id === this.curSubjectInfo.id) {
        obj = item
      }
    })
    return obj
  }

  mounted() {

    if(this.isCountdown){
      if (this.examDetail.latest_paper) {
        this.remainTime = this.examDetail.latest_paper.remain_time
      } else {
        this.remainTime = this.examDetail.time_limit * 60
      }
      if (this.remainTime && this.renderType === 'exam') { // 考试时间到了
        this.countdown()
      } else if (this.renderType === 'exam') {
        if (this.isSectionFreeStudy) {
          eventBus.$emit('header.showDialog')
        } else {
          this.$alert('考试时间到了，请教卷！', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              eventBus.$emit('index.autoSubmitExam')
            }
          });
        }
      }
    }

    this.$nextTick(() => {
      this.getExamDetail()
    })

    eventBus.$on('header.showDialog', (detail: any) => {
      console.log(detail, 'detail');
      
      this.dealDialogShow(detail)
    })
  }

  destroyed() {
    this.cancel()
  }

  // 考试倒计时
  countdown() {
    if (this.remainTime > 0) {
      this.timer = setInterval(() => {
        const count = counterTimeBySec(this.remainTime)
        if (!count.valid) {
          if (this.isSectionFreeStudy) {
            eventBus.$emit('header.showDialog')
          } else {
            this.$alert('考试时间到了，请教卷！', '提示', {
              confirmButtonText: '确定',
              callback: action => {
                eventBus.$emit('index.autoSubmitExam')
              }
            });
          }
          this.cancel()
        } else {
          if (Number(count.day)) {
            this.countdownText = `${count.day}日${count.hour}时${count.min}分${count.sec}秒`
          } else {
            this.countdownText = `${count.hour}时${count.min}分${count.sec}秒`
          }
          this.remainTime--
        }
      }, 1000)
    }
  }

  cancel() {
    clearInterval(this.timer)
  }

  dealDialogShow(detail:any) {
    if (this.isSectionFreeStudy) { 
      
    } else {
      detail = detail || this.answerCardDetail
      if (!detail.skip_count) { // 已经全部答完
        this.$confirm('试题已做完，确认交卷吗?', '提示', {
          confirmButtonText: '确认交卷',
          cancelButtonText: '检查一下',
          type: 'warning'
        }).then(() => {
          this.submitExam()
        }).catch(() => {         
        });
      } else {
        console.log('有没有进来');
          
        this.centerDialogVisible = true
      }
    }
  }

  // 交卷
  submitExam() {
    this.$emit('submitExam')
  }

  showSubmitDialog() {
    this.centerDialogVisible = false
    eventBus.$emit('index.autoSubmitExam')
  }

  getExamDetail(){
    getExamDetail(this.id).then(res => {
      this.detail = res
      // console.log(this.detail, 'this.detail');
      const count = counterTimeBySec(res.time_limit * 60)
      let timeText = ''
      if (Number(count.day)) {
        timeText = `${count.day}日${count.hour}时${count.min}分`
      } else {
        timeText = `${count.hour}时${count.min}分`
      }
      this.timeText = timeText
    }).catch(e => {
    })
  }

  onSelect() {
    this.centerDialogVisible = false
  }
}
