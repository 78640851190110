import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'

@Component
export default class examPage extends Vue {
  @Prop() id!: any
  @Prop() examType!: any  // exam、practice  试卷类型
  @Prop() renderType!: any  // exam、analysis  渲染类型
  @Prop() curSubId!: any  // 查看某道题的解析的 题目id
  @Prop() subType!: any
  @Prop() contentId!: any
  @Prop() contentType!: any
  get examList() {
    // console.log(this.$store.state.exam.examList, '列表更新')
    return this.$store.state.exam.examList
  }
  get curSubjectInfo() {
    // console.log(this.$store.state.exam, 'this.$store.state.exam.curSubjectInfo');
    console.log(this.$store, 'this.$store');
    return this.$store.state.exam.curSubjectInfo

  }
  get allCardList() {
    return this.$store.state.exam.allCardList
  }
}