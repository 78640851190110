import { formatTime as ft } from './formatTime'

/**
 * 格式化时间
 *
 * @param {String} str
 * @returns 格式化后的时间
 */
/* eslint-disable */
export const formatTime = ft

let {pathname} = window.location

let shopId = pathname.replace(/[\w-]*(\w+).html/, '').split('/')[1] || ''

export const setShopId = (e: any) => {
  shopId = e
}

export const getShopId = () => {
  return shopId
}

/*
 * 本地存储
 * */
export const storage = {
  method: 'localStorage',
  // set: (key, value, time, method) => {
  //   var data, valid;
  //   key = [getShopId(), (key || 'duanshu')].join('.');
  //   method = method || storage.method;
  //   data = {
  //     value: JSON.stringify(value)
  //   };
  //   if (typeof time == 'number') {
  //     // 这里是一个坑 timestamp 是毫秒
  //     // time 单位为 秒
  //     if (time.toString().length == 10) {
  //       // 时间点
  //       data.timestamp = time * 1e3;
  //     } else {
  //       // 时间间隔
  //       valid = time * 1e3;
  //       data.timestamp = (new Date).getTime() + valid
  //     }
  //   } else {
  //     data.timestamp = null;
  //   }
  //   return window[method].setItem(key, JSON.stringify(data));
  // },
  get: (key: any, method: any) => {
    var data;
    key = [getShopId(), (key || 'duanshu')].join('.');
    method = method || storage.method;
    data = JSON.parse(window[method].getItem(key));
    return data && data.value ? data.timestamp === null ? JSON.parse(data.value) : (new Date).getTime() < data.timestamp && JSON.parse(data.value) || !1 : !1;
  },
  // remove: (key, method) => {
  //   key = [getShopId(), (key || 'duanshu')].join('.');
  //   method = method || storage.method;
  //   window[method].removeItem(key);
  // },
  // clear: (method) => {
  //   method = method || storage.method;
  //   window[method].clear();
  // }
};


export const windowTitle = (title: any) => {
  let shoppingInfo = storage.get('shoppingInfo', 'sessionStorage')
  if (title) {
    window.document.title = title
    console.log('window.document.title: ', window.document.title)
  } else {
    let t = ''
    if (shoppingInfo) {
      t = shoppingInfo.shop && shoppingInfo.shop.title
    }
    window.document.title = t
    console.log('window.document.title2: ', window.document.title)
  }
  /*
  if (mobileDevice() == 'iOS') {
    const hackIframe = document.createElement('iframe')
    hackIframe.style.display = 'none'
    hackIframe.src = '//oss-web.duanshu.com/ds_projects/ds_app_tech/static/img/favicon.ico?random=' + Math.random()

    document.body.appendChild(hackIframe)

    setTimeout(_ => {
      document.body.removeChild(hackIframe)
    }, 300)
  }
  */
};

/* 获得当前英文字母编号 */
export function getEnglishChar (num: any) {
  let baseCode = 65 + num
  return String.fromCharCode(baseCode)
}
// export function urlToTagHtml(v) {
//   return v.replace(
//     /(((https?\:\/\/)|(www\.))[A-Za-z0-9]+\.[A-Za-z0-9]+[\/=\?%\-&_~`@[\]\':+!]*([^<>\"\"\s])*)/gi,
//     function(url){
//       let full_url = url;
//       if (!full_url.match('^https?:\/\/')) {
//         full_url = 'http://' + full_url;
//       }
//       let link = `<a class="g-html-a" onclick="arguments[0].stopPropagation()" style="display:inline-block;word-break: break-all;" href="${full_url}" >${url}</a>`;
//       return link;
//     }
//   )
// }

export const formatCountTime = (milliseconds: any) => {
  let diff = milliseconds;

  const UNITS = {
		'年': 31557600000,
		'月': 2629800000,
		'天': 86400000,
		'小时': 3600000,
		'分钟': 60000,
		'秒': 1000
	}

  let day = 0;
  let hour = 0;
  let min = 0;
  let sec = 0;
  let msec = 0;

  if (diff >= UNITS['天']) {
     day = Math.floor(diff / UNITS['天']);
     diff -= (day * UNITS['天']);
  }
  if (diff >= UNITS['小时']) {
    hour = Math.floor(diff / UNITS['小时']);
    diff -= (hour * UNITS['小时']);
  }
  if (diff >= UNITS['分钟']) {
    min = Math.floor(diff / UNITS['分钟']);
    diff -= (min * UNITS['分钟']);
  }
  if (diff >= UNITS['秒']) {
    sec = Math.floor(diff / UNITS['秒']);
    diff -= (sec * UNITS['秒']);
  }
  msec = diff;

  var _format = function (number: any) {
    return (number < 10 ? ('0' + number) : number);
  };

  if (milliseconds > 0) {

    var time = '';
    time += day ? _format(day) + '天' : '';
    time += _format(hour) + ':';
    time += _format(min) + ':';
    time += _format(sec);
    return time;
    // return _format(day) + '天' + _format(hour) + ':' + _format(min) + ':' + _format(sec) + ':' + _format(msec);
  } else{
    return '00:00:00';
  }
}

/* html转义 */
// export const noEscapeHtml = (html: any) => {
//   return html.replace(/[<>&"]/g, (c: string | number) => {
//     return {
//       '<': '&lt;',
//       '>': '&gt;',
//       '&': '&amp;',
//       '"': '&quot;'
//     }[c]
//   })
// }

export const counterTimeBySec = (sec: any) => {
  var range = sec;
  var counter: any = {};
  if (range < 0) {
    range = 0 - range;
    counter.valid = false;
  } else {
    counter.valid = true;
  }

  counter.day = operateTime(Math.floor(range / (24 * 60 * 60)));
  counter.hour = operateTime(Math.floor(range % (24 * 60 * 60) / (60 * 60)));
  counter.min = operateTime(Math.floor(range % (24 * 60 * 60) % (60 * 60) / 60));
  counter.sec = operateTime(Math.floor(range % (24 * 60 * 60) % (60 * 60) % 60));
  return counter;
}

export const operateTime = (time: any) => {
  return time < 10 ? '0' + time : time
}
// 计算字长度，汉字字符 += 2，其他字符 += 1
export const checkWordSize = (text:any) => {
  if (typeof text !== 'string' || !text.length) {
    return text
  }
  let size = 0
  let reg = /[^\x00-\xff]/
  for (let i = 0; i < text.length; i++) {
    if (reg.test(text[i])) {
      size += 2
    } else {
      size += 1
    }
  }
  return size
}
const jsArray:any = {
  'cos-js-sdk-v5': '//oss-web.duanshu.com/ds_projects/ds_app_tech/static/js/cos-js-sdk-v5.min.js',
  'vod-js-sdk-v6': '//cdn-go.cn/cdn/vod-js-sdk-v6/latest/vod-js-sdk-v6.js',
  'webRTC-adapter': '//oss-web.duanshu.com/ds_projects/ds_app_tech/static/js/adapter.js'
}
export const loadJsPromise = (name:any) => {
  if (!jsArray.hasOwnProperty(name)) {
    return Promise.reject('找不到' + name)
  }
  const el = document.createElement('script')
  el.src = jsArray[name]
  document.body.appendChild(el)
  const promise = new Promise<void>((resolve, reject) => {
    el.onload = () => {
      console.log('onload')
      return resolve()
    }
    el.onerror = (e) => {
      console.log('onerror')
      return reject(e)
    }
  })
  return promise
}
/*
 * 获取随机串
 * */
export const randomNum = (len:any) => {
  var salt = '';
  for (var i = 0; i < len; i++) {
    var tmp:any = parseInt( Math.floor(Math.random() * 10).toString());
    if (!tmp) {
      tmp = '2';
    }
    salt += tmp;
  }
  return salt;
};
const qcloudSetting:any = {
  appid: '1253562005',
  bucket_name: 'duanshu',
  client_id: 'common',
  biz_type: 'content',
  dir_name: 'dsapply',
  test_name: 'test',
  district: 'sh',
  video: 'https://vod.qcloud.com/v2/index.php'
};
export const getTime = () => {
  const myDate = new Date();
  const year = myDate.getFullYear();
  const mon = myDate.getMonth() + 1;
  const dd = myDate.getDate();
  const hh = myDate.getHours();
  const month = (mon < 10 ? "0" + mon : mon);
  const day = dd < 10 ? '0' + dd : dd;
  const hour = hh < 10 ? '0' + hh : hh;
  const string = '/' + year + '/' + month + '/' + day + '/' + hour;
  return string;
};