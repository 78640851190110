




















import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import { eventBus } from '@/modules/exam/filters/event-bus'
import commonMixin from '@/modules/exam/mixins/commonMixin'

@Component({
  // components: {
  //   answerCard
  //   // dialogWrap
  // }
})
export default class ClassWrap extends Mixins(commonMixin) {
  @Prop() cardList!: any

  get emptyElmLen() {
    // justify-content:space-between 样式补空元素
    let empty = this.cardList.length % 5
    return empty === 0 ? empty : 5 - empty
  }

  onSelect(obj: any) {
    this.$emit('onSelect')
    // 切换题目
    const index = this.examList.findIndex((item: any) => {
      return item.id === obj.id
    })
    if (index === -1) { // 题目列表中没有该题，需要去请求接口
      eventBus.$emit('index.changeSubject', obj)
    } else {
      eventBus.$emit('index.exam.submitRecord', this.examList[index])
    }
  }
}
